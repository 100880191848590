.fade-in {
    opacity: 0;
    animation: fadeIn 0.5s forwards;
}

.fade-out {
    opacity: 1;
    animation: fadeOut 0.5s forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
