@tailwind base;
@tailwind components;
@tailwind utilities;
@import "@fortawesome/fontawesome-free/css/all.css";

body {
  margin: 0;
  font-family: "Adamina", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Adamina", serif;
  font-weight: 400;
  font-style: normal;
}

.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.swiper-pagination {
  bottom: -20px; /* Adjust to position below the images */
  text-align: center;
}
.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #b07641; /* Customize the color */
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background-color: #000000; /* Color for active bullet */
}
